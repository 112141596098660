import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import range from 'lodash/range'
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import Box from 'components/Box'
import NewsLayout from 'components/NewsLayout'
import ContentLayout from 'components/ContentLayout'

import useResponsive from 'contexts/mediaQuery/useResponsive'

const StyledTabs = styled(Tabs)`
`

const StyledTabList = styled(TabList)`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`

const StyledTab = styled(Tab)`
  width: 49%;
  display: block;
  position: relative;
  font-size: ${props => props.isMobile ? '1em' : '1.625em'};
  padding: 1.125rem 0 0.75rem 0;
  text-align: center;
  background: white;
  border-radius: 0.75em 0.75em 0 0;
  transition: all 0.5s;
  &:hover,
  &.react-tabs__tab--selected {
    color: white;
    border-radius: 0.75em 0.75em 0 0;
    background: ${themeGet('colors.blue')};
    &::after {
      display: none;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: ${props => props.isMobile ? '0.75em' : '1.75em'};
    right: ${props => props.isMobile ? '0.75em' : '1.75em'};
    bottom: 0;
    height: 4px;
    background: ${themeGet('colors.lightPurple')};
  }
`

const NewsPage = () => {
  const { isMobile } = useResponsive()
  return (
    <Box>
      <ContentLayout title="公告訊息">
        <StyledTabs>
          <StyledTabList>
            <StyledTab isMobile={isMobile} tabIndex="0">法制局公告</StyledTab>
            <StyledTab isMobile={isMobile} tabIndex="0">各區調解委員會公告</StyledTab>
          </StyledTabList>
          {range(2).map((i) => (
            <TabPanel key={i}>
              <NewsLayout path={`org/${i + 1}/news`} i={i} />
            </TabPanel>
          ))}
        </StyledTabs>
      </ContentLayout>
    </Box>
  )
};

export default NewsPage;
